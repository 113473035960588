/* Palette generated by Material Palette - materialpalette.com/light-blue/amber */
$color-primary-dark: #1e3643;
// rgba(2, 136, 209, 1)
$color-primary: #03a9f4;
$color-primary-light: #b3e5fc;
// rgba(179, 229, 252, 1)
$color-light: #ffffff;
$color-secondary: #ffc107;
// rgba(255, 193, 7, 1)
$color-dark: #212121;
$text-secondary-color: #757575;
$divider-color: #bdbdbd;

$gradient: linear-gradient(87deg, #03a9f4, #03a9f475),
  linear-gradient(308deg, #0e3b50, #0e3b5000),
  linear-gradient(273deg, #03a9f4, #03a9f475);

//other colors (not used)
$color-dark-blue: #0288d1;

$font-primary: "Poppins", sans-serif;
$font-secondary: "Open Sans", sans-serif;

$xpadding: 5rem;
$xpadding-sm: 1rem;

$ymargin-btm: 7rem;
$ymargin-btm-sm: 3rem;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-secondary;
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $font-secondary;
  color: $color-primary-dark;
}

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none;
}

section {
  margin-bottom: $ymargin-btm-sm;

}

.section-header {
  text-align: center;
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .section {
    margin-bottom: $ymargin-btm;
  }

  .section-header {
    margin-bottom: 5rem;
  }
}

.section-header hr {
  width: 6rem;
  background: $color-secondary;
  height: 2px;
  border: none;
}

.section-header h2 {
  margin: 0;
}

.page-wrapper {
  padding: 0 $xpadding-sm;
  margin: 0 auto;
}

//icons
.image-express {
  padding: 0.2rem 0;
  box-sizing: border-box;
}

.image-css {
  padding: 0.4rem;
  box-sizing: border-box;
}

.image-github {
  width: 3rem;
}

//CSS Transitions Classes
//appear on load transition
.fade-appear {
  opacity: 0;
  z-index: 1;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 1000ms linear;
}

//enter transition
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter-active {
  opacity: 1;
  transition: all 500ms linear;
}

//exit
.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: all 300ms linear;
  display: none;
}

.fade-exit-done {
  opacity: 0;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}

.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

@media (min-width: 768px) {
  .page-wrapper {
    padding: 0 $xpadding;
  }
}

@import "./Styles/Navbar.scss";
@import "./Styles/Footer.scss";
@import "./Styles/AboutPage.scss";
@import "./Styles/HomePage.scss";

@import "./Styles/IntroSection.scss";
@import "./Styles/Work.scss";
@import "./Styles/ContactSection.scss";

@import "./Styles/Cards.scss";
@import "./Styles/Card.scss";

@import "./Styles/IconChevron";

@import "./Styles/Utilities.scss";

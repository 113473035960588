.bold {
  font-weight: 500;
}

.about-wrapper {
  display: flex;
  gap: 3rem;
}

.about-col-left {
  width: 50%;
}

.about-text,
.about-skills,
.about-technologies {
  margin-bottom: 4rem;
}

.about-title {
  font-size: 2rem;
  margin-top: 0;
}

.about-icons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media (min-width: 576px) {
  .about-title {
    font-size: 3rem;
  }
}

.about-text {
  font-size: 1rem;
}

.about-col-right {
  width: 50%;
  flex-grow: 1;
}

.about-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
